import React from "react";
import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMagnifyingGlass } from "@coreui/icons";

const TermsAndConditionsPage = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          {/* <CCol md={6}> */}
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <h1>Terms and Conditions of Amfree</h1>

            <>
              {/* <p>Terms and Conditions of Amfree</p> */}
              <p>Welcome to Amfree!</p>
              <p>
                These Terms of Use regulate the usage of the Amfree application
                and services, unless where we have clearly mentioned that
                alternative terms (not these ones) apply, and also provide
                details about the Amfree service (the "Service"), as described
                below. When you register an Amfree account or use Amfree, you
                comply with these Terms. The Amfree Terms of Service are
                necessary for the Amfree platform.
              </p>
              <p>
                Amfree Technologies LLP endows you with the Amfree Service.
                These Terms of Use make a contract between you and Amfree
                Technologies LLP.
              </p>
              <p>The Amfree Service</p>
              <p>
                We promise to make the Amfree Service available to you. The
                Service includes all the Amfree products, features,
                applications, services, technologies, and software that we
                provide to advance Amfree's mission of creating valuable
                connections and network opportunities. The Service consists of
                the following aspects:
              </p>
              <p>
                Offering Personalized Opportunities to Connect and Network
                Amfree provides a user-friendly and reactive space allowing
                individuals from different professions, businesses, and those of
                common interests to connect. The platform grants its users the
                authorizations to browse through potential acquaintances and
                also to engage with others through enjoyable encounters. Whether
                you're growing your business links or entering into new business
                associations, Amfree offers various types of accounts and
                features that enable you to connect, share, develop your brand,
                and communicate with other users both within and outside the
                platform. We establish systems that try to fathom out your
                preferences as a means to introduce to you the experiences that
                matter most to you.
              </p>
              <p>
                {" "}
                Fostering a Positive, Inclusive, and Safe Environment We develop
                and use tools and provide resources to make our community
                members feel interested and engaged. We have groups and
                technical resources devoted to forestall abuse and breaches of
                our conditions and policies. We won't hesitate to use all the
                information at our disposal, including your data, to maintain
                the security of our platform. In addition to this, we can also
                share with other entities or the police implications of abusive
                or harmful content found on our platform. You can find more
                specifics about this in our Privacy Policy.
              </p>
              <p>
                Developing and Using Technologies to Serve Our Growing Community
                Our Service is mainly inclined towards the production and
                application of advanced technologies for the purpose of the
                personalized, safeguarded and enhanced Service available to our
                community. Technologies such as artificial intelligence and
                machine learning are the enabling agents that oversee the
                complexities of the system so that it remains functional and
                secure for our global community.
              </p>
              <p>
                {" "}
                Providing Consistent and Seamless Experiences Across Devices
                Amfree is developed so that the interaction takes place on any
                type of device. We make sure that the Service is accessible to
                you across any device you use by utilizing technology, systems,
                insights, and information across our infrastructure to
                facilitate the encounter.
              </p>
              <p>
                {" "}
                Ensuring Access to Our Service To operate our global Service, we
                have to store the data and transfer it across the systems
                worldwide, including the ones outside your country of residence.
                The use of this global infrastructure is obligatory in order to
                make the Service more efficient. The infrastructure can be owned
                or operated by Amfree Technologies LLP or its affiliates.
              </p>
              <p>
                Connecting You with Opportunities We take data that we have
                gathered from our platform and our business partners to show you
                opportunities that are relevant to you. These may range from
                access to exclusive content to a significant investment in a
                business opportunity. We want to be very transparent and the
                whole process is in a safe and secure manner.
              </p>
              <p>
                Research and Innovation We utilize the data collected to analyze
                the Service and work on research that enhances our platform and
                the well-being of our community.
              </p>
              <p>
                {" "}
                How Our Service Is Funded Instead of charging you to be an
                Amfree user, the Terms of Service govern your use of the Service
                and hence you accept that we will show you opportunities that
                other users and organizations pay us to promote. We use your
                personal data, like what you do and what you like, to provide
                you with deals that are specific to you, and which may be of
                interest to you. We don't sell your personal information to
                anyone.
              </p>
              <p>
                We may let users see reports on how their posts or connections
                are performing in order to improve their content performance on
                Amfree. For instance, we might give them general demographic and
                interest data to make them better understand their audience.
                Without your specific permission, we don't reveal the
                information that directly identifies you. Get to know more about
                how Amfree works here.
              </p>
              <p>
                The Privacy Policy We assist in providing the Service by the
                collection and usage of your details. The Privacy Policy
                elucidates on the matters related to data collection, usage, and
                distribution in Amfree. It also comprises multiple ways in which
                you can handle your information including using the Amfree
                Privacy and Security Settings. You have to agree to the Privacy
                Policy in order to avail yourself of Amfree.
              </p>
              <p>
                Your Commitments Our commitment to provide the Service to you is
                our invariable service, and you are thus required to make the
                following commitments to us.
              </p>
              <p>
                Who Can Use Amfree Maybe You shouldn’t just become an artful
                dodger with us, right? Nevertheless, thanks to the law, we all
                have some common agreement as humans living together. In this
                respect, we should forward the museum to the citizens of today
                while still keeping it safe, secure, and legal. To, list, the
                following criteria must be met:
              </p>
              <p>
                - You must be at least 13 years old or the minimum legal age in
                your country to use Amfree. - You must not be prohibited from
                receiving any aspect of our Service under applicable laws or
                engaging in payments related services if you are on an
                applicable denied party listing. - We must not have previously
                disabled your account for violation of law or any of our
                policies. - You must not be a convicted sex offender.
              </p>
              <p>
                How You Can't Use Amfree Our aim remains to keep the platform a
                welcoming and secure place for everyone. So as to this end, we
                expect all the users to adhere to some of the nuances herein.
                Therefore, as a way of being on the same wavelength, each user
                must comply with the following rules: - Never make the
                pretending that others are you or someone else's is the right
                path to take or give untrue information to others. - You can't
                do anything unlawful, misleading, or fraudulent or for an
                illegal or unauthorized purpose. - You can’t do anything to
                violate (or help or encourage others to violate) these Terms and
                the Amfree policies are not to be the exception. - You can't do
                anything to disrupt or destroy the Service's normal operation. -
                You can't try to create or hack accounts or access our system to
                access unauthorized information. - You can’t sell, license, or
                buy an account or data taken from us or our Service. - You can't
                post private or confidential info given by another individual
                without their permission or infringe upon the rights of others,
                for example, intellectual property rights. - You can’t
                reproduce, translate, create derivative works, or reverse our
                products or their constituent parts.
              </p>
              <p>
                Permissions You Give to Us In honor of our contract, we are also
                given the rights to use your content for the Service: - License
                to Use Your Content: We do not claim copyright of the content,
                but we are allowed to use it by you. This license will end when
                your content is deleted from our systems. - Permission to Use
                Your Profile Information: We ask you to comply with us by using
                your username, which will, in turn, give us your profile
                picture, and information about your relationships and actions
                with accounts, offers, and sponsored content, without any
                compensation to you. - Permission to Update the Service: It is
                your duty that we download and install updates to the Service on
                your device.
              </p>
              <p>
                Additional Rights We Retain -We reserve the right to modify your
                username, e.g., if Your username is a script of the property of
                another person, or it looks like another user's account. - We
                hold all the rights of our content meaning all the rights,
                including intellectual property rights and trademarks, that are
                acquired by us through explicitly described terms included in
                our brand guidelines or under the condition that we pre-confirm
                by our prior written consent are with us. - Open-source
                permission has to be signed by us to allow you to make changes
                and gains after the writing of a specific letter. You will not
                be able to change, the derivative works of us or decompile or
                attempt to extract source code from us without our written
                permission or in accord with an open-source license.
              </p>
              <p>
                Content Removal and Disabling or Terminating Your Account
                Through our platform, we are allowed to weed out any information
                that has the potential to break the law, is against the
                influence on the site, or is in direct contrast to the policy.
                Furthermore, we are also able to discontinue the service or
                freeze your account if you dare to break any of the law or
                policy. Nevertheless, in the case of his or her account
                termination, the liability of the service provider shall cease
                to exist together with the agreement. However, some sections may
                still be effective.
              </p>
              <p>
                Our Agreement and What Happens if We Disagree - This Agreement:
                If a single clause or section of this agreement is proven to be
                unenforceable, the rest will continue as they were initially
                written. - Who Has Rights Under this Agreement: Contrary to
                popular belief, this agreement does not give third parties the
                right to take part in this project. We are the ones who have the
                right to delegate our responsibilities and the right to benefits
                can be held by the other party. - Who Is Responsible if
                Something Happens: Our Service is given to you "as is" and we do
                not promise that it will be quite safe and secure, or work
                perfectly all the time. To the full extent otherwise permitted
                by law, we limit responsibility for anything that happens on the
                Service. - How We Will Handle Disputes: These laws are
                applicable to consumer claims, cause of action, or disputes that
                occur if you are a consumer residing in a country. Other than
                that, disputes must be resolved solely in the courts of Amfree
                Technologies LLP's home country only and nowhere else. You
                cannot force the issue.
              </p>
              <p>
                Unsolicited Material We are happy that you give us this feedback
                or that you recommend us for new and fun projects, but we will
                attract and use them freely without any obligations of
                compensation for the source.
              </p>
              <p />
              <p>
                Updating These Terms Maybe we don't need to modify the program,
                as well as the policy, which will allow us to be more efficient
                in case we need to make any adjustments to these Terms so that
                they are in sync with the Service and our policies. The change
                will happen only if we inform you first and give you an
                opportunity to see the new version before it comes into force.
                However, if you decide to continue using the Service, the new
                version of the Terms will apply to you. You will have to accept
                what has been updated.
              </p>
              <p>
                Effective Date: 15-Aug-2024 For any inquiries or requests
                related to these Terms, please contact us at amfreeconnect@gmail.com.
              </p>
            </>
          </div>
          {/* </CCol> */}
        </CRow>
      </CContainer>
    </div>
  );
};

export default TermsAndConditionsPage;
