import React from "react";
import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMagnifyingGlass } from "@coreui/icons";

const AboutUsPage = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          {/* <CCol md={6}> */}
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <h1>About Amfree</h1>

            <>
              {/* <p>About Amfree</p> */}
              <p>Welcome to Amfree</p>
              <p>Amfree welcomes you with open arms</p>
              <p>
                Amfree completely redefines networking by merging meaningful
                relationships with actual business opportunities. Our goal is to
                create a platform where all interactions have meaning and are
                valuable. We know that strategic networks are essential for both
                personal and professional success in the world of today’s
                connectedness.
              </p>
              <p>Why Amfree Stands Out</p>
              <p>
                There is an abundance of networking options but Amfree stands
                out by incorporating monetary rewards into its networking
                process. This innovative model guarantees you that your time and
                effort will yield something valuable. Whether you want to widen
                your professional network, start new enterprises or simply
                interact with like-minded people, then look no further than this
                complex yet gratifying solution by Amfree.
              </p>
              <p>How Amfree Works</p>
              <p>
                Amfree operates on an innovative model that enables users to
                incentivize their relationships using money. Here is how it
                happens:
              </p>
              <p>
                Incentive-Driven Connections: Users can choose various
                incentives such as nominal fees for access to exclusive content
                or huge investments for collaboration purposes. The system
                ensures that both parties are committed thus enhancing
                engagement and productivity. Safe and Transparent Transactions:
                Security and trust are among our priorities. All transactions
                are characterized by transparency, integrity to make the journey
                seamless and reliable. Customized Networking Opportunities:
                Amfree’s matching algorithm connects users with similar
                interests, professional objectives or networking needs so that
                your contacts are both relevant and useful. Our Philosophy
                Integrity and Transparency: Our platform is forever committed to
                honesty in all its operations including interactions and
                transactions made. Cooperation and Support: The members of our
                community thrive on collaboration; they share ideas, offer
                support, and work together towards a common goal. Empowerment
                and Respect: We celebrate diversity in this network promoting
                respect at every level of interaction. What We Promise You
                Developing Genuine Relationships: Amfree aims at providing you
                with the necessary tools that will change your connections into
                lasting relationships which bear fruits. Enhancing Professional
                Growth: Whatever growth stage your business is, from start-ups
                looking for investors to professionals in need of mentors,
                Amfree is there for you. Creating Valuable Opportunities: At
                Amfree we strive to open up fresh paths for collaboration,
                investment opportunities as well as career growth thus ensuring
                tangible benefits out of your networking endeavors. Be Part of
                Our Network Journey Unleash endless possibilities with Amfree.
                Get the app today and transform your networking experience. If
                you are an ambitious entrepreneur, experienced professional or
                simply want more friends in life; then here is where you belong
                as we have a world full of opportunities. Connect. Network.
                Thrive. #Amfree #Networking #Connections
              </p>
            </>
          </div>
          {/* </CCol> */}
        </CRow>
      </CContainer>
    </div>
  );
};

export default AboutUsPage;
