import React, { Component, Suspense } from "react";
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { asyncLocalStorage } from "./services/utils";
import backgroundMusic from './waterfall-bg.mp3';
import TermsAndConditionsPage from "./views/pages/terms-conditions/TermsConditions";
import AboutUsPage from "./views/pages/about-us/AboutUs";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const PrivacyPolicyPage = React.lazy(() => import("./views/pages/privacy-policy/PrivacyPolicy"));

class App extends Component {
  state = {
    isLoggedIn: false,
  };
  async componentDidMount() {
    const isLoggedIn = await asyncLocalStorage?.getItem().then((data) => {
      return data;
    });
    this.setState({
      isLoggedIn: isLoggedIn ? true : false,
    });
    // window.addEventListener('click', this.playAudio);
  }

  playAudio = () => {
    // const audio = document.querySelector('audio');
    // audio.play();
    // window.removeEventListener('click', this.playAudio);
  }
  render() {
    return (
      <BrowserRouter>
      {/* <audio src={backgroundMusic} autoPlay loop/> */}
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={this.state.isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />}
            />
            {/* <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            /> */}
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />

            {/* Privacy Policy */}
            <Route exact path="/privacy-policy" name="Privacy Policy" element={<PrivacyPolicyPage />} />
            <Route exact path="/terms-conditions" name="Terms And Conditions" element={<TermsAndConditionsPage />} />
            <Route exact path="/about-us" name="About Us" element={<AboutUsPage />} />


            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
